import { 
  createBrowserRouter, 
  RouterProvider 
} from 'react-router-dom';

import App from './App';
import ErrorPage from './pages/ErrorPage';
import Landing from './pages/Landing';
import GroupPage from './pages/GroupPage';
import ContactMe from './pages/ContactMe';
import PrivacyTerms from './pages/PrivacyTerms';
import EbookPage from './pages/EbookPage';

const routes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Landing />,
      },
      {
        path: '/grupo',
        element: <GroupPage />,
      },
      {
        path: '/conte-sua-historia',
        element: <ContactMe />,
      },
      {
        path: '/termos-de-uso-e-privacidade',
        element: <PrivacyTerms />,
      },
      {
        path: '/ebook',
        element: <EbookPage />,
      },      
    ],
  },  
]);

function Routes() {
  return (
    <RouterProvider router={routes}/>
  );
}

export default Routes;
