import { useEffect } from 'react';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import Group from '../../components/PageComponents/Group';

import './styles.css';

function GroupPage() {

  const pageName = 'Grupo das sacerdotisas';
  const gaEventTracker = useAnalyticsEventTracker(pageName);

  useEffect(() => {
    gaEventTracker.sendPageView('/grupo', pageName);
  }, []);

  return (
    <div id="group-page" className="container">
      <Group />
    </div>
  );
}

export default GroupPage;
