import React from "react";
import { Link } from "react-router-dom";

import useAnalyticsEventTracker from '../../../utils/useAnalyticsEventTracker';

import Footer from "../Footer";

import './styles.css';

const Group: React.FC = () => {

  const pageName = 'Grupo das sacerdotisas';
  const gaEventTracker = useAnalyticsEventTracker(pageName);

  return (
    <div className="group">
      <div className="group-content">
        <div className="group-main">
          <strong>O poder das Sacerdotisas reside na</strong>
          <strong>conexão profunda com a</strong>
          <strong>espiritualidade, guiando com</strong>
          <strong>sabedoria e amor a jornada da alma.</strong>
        </div>
        <div className="links">
          <div id='playlist' className="text-box">
            <Link to='/conte-sua-historia'>
              <strong>PARTICIPE DO GRUPO</strong>
              <p>CONTE A SUA HISTÓRIA AQUI</p>
            </Link>          
          </div>
          {/*<div id='grupo' className="text-box">
            <a href="https://discord.gg/dzpTf7dR" onClick={()=> gaEventTracker.eventTracker('Visitar grupo discord', 'Visitar grupo discord')}>
              <strong>PARTICIPE DO GRUPO</strong>
            </a>       
          </div>*/}
        </div>  
        <Footer showPrivacyTerms={true} pageName={pageName} />
      </div>
    </div>
  );
};

export default Group;
