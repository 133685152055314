import React from "react";
import { Link } from 'react-router-dom';

import useAnalyticsEventTracker from '../../../utils/useAnalyticsEventTracker';

import Footer from "../Footer";

import foto from '../../../assets/images/foto.png';

import './styles.css';

const Main: React.FC = () => {

  const pageName = 'Página principal';
  const gaEventTracker = useAnalyticsEventTracker(pageName);

  return (
    <div className="main">
      <div className="main-content">
        <div className='foto'>
          <img src={foto} alt="Foto Jaime" />
        </div>
        <h1>O Caçador de Sacerdotisas</h1>
        <h2>Por Jaime Benedetti</h2>
        <div className="links">
          <div id='ebook' className="text-box">
            <Link to='/ebook'>
              <strong>E-BOOK E LIVRO</strong>
            </Link>          
          </div>
          <div id='playlist' className="text-box">
            <a href="https://youtube.com/playlist?list=PLUfGqfL_-oloqTyqLG4Bg2JRzks4pyUPr&si=ah1tSef8Udqj0Z92" target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar playlist', 'Visitar playlist')}>
              <strong>CLIQUE PARA ASSISTIR</strong>
            </a>          
          </div>
          <div id='grupo' className="text-box">
            <Link to='/grupo'>
              <strong>O GRUPO DAS SACERDOTISAS</strong>
            </Link>          
          </div>          
        </div>        
        <Footer showPrivacyTerms={false} pageName={pageName}/>
      </div>
    </div>
  );
}

export default Main;
