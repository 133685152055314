import React from "react";
import { Link } from 'react-router-dom';

import useAnalyticsEventTracker from '../../../utils/useAnalyticsEventTracker';

import emailIcon from '../../../assets/images/icons/email.png';
import instagramIcon from '../../../assets/images/icons/instagram.png';
import whatsappIcon from '../../../assets/images/icons/whatsapp.png';
import youtubeIcon from '../../../assets/images/icons/youtube.png';
import logoTruenumbers from '../../../assets/images/logos/truenumbers.png';

import './styles.css';

interface Props {
  showPrivacyTerms: boolean,
  pageName: string, 
}

type props = Props

const Footer = ({ showPrivacyTerms, pageName } : props) => {

  const gaEventTracker = useAnalyticsEventTracker(pageName);

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="midias">
          {
            showPrivacyTerms &&
            <Link id='termos' to='/termos-de-uso-e-privacidade'>Política de Privacidade e Segurança</Link>
          }    
        </div>
        <div className='logo'>
          <a href='https://www.truenumbers.com.br' target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar truenumbers', 'Visitar truenumbers')}>
            <img src={logoTruenumbers} alt='logo_truenumbers' />
          </a>          
        </div>        
        <div className='midias'>
          <div className='midia'>
            <a href='https://www.truenumbers.com.br/fale-conosco' target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar fale-conosco truenumbers', 'Visitar fale-conosco truenumbers')}>
              <img src={emailIcon} alt='email' />
            </a>            
            <strong>Email</strong>
          </div>
          <div className='midia'>
            <a href='https://wa.me/5511992186188' target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Acessar whatsapp', 'Acessar whatsapp')}>
              <img src={whatsappIcon} alt='whatsapp' />
            </a>            
            <strong>Whatsapp</strong>
          </div>
          <div className='midia'>
            <a href='https://instagram.com/truenumbers_?igshid=NzZlODBkYWE4Ng==' target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar instagram', 'Visitar instagram')}>
              <img src={instagramIcon} alt='instagram' />
            </a>            
            <strong>Instagram</strong>
          </div>
          <div className='midia'>
            <a href='https://youtube.com/playlist?list=PLUfGqfL_-oloqTyqLG4Bg2JRzks4pyUPr&si=ah1tSef8Udqj0Z92' target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar youtube', 'Visitar youtube')}>
              <img src={youtubeIcon} alt='youtube' />
            </a>            
            <strong>Youtube</strong>
          </div>          
        </div>
      </div>
    </footer>
  ); 
};

export default Footer;
