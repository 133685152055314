import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import Footer from '../../components/PageComponents/Footer';

import './styles.css';

function PrivacyTerms() {

  const pageName = 'Termos de uso e privacidade'
  const gaEventTracker = useAnalyticsEventTracker(pageName);

  useEffect(() => {
    gaEventTracker.sendPageView('/termos-de-uso-e-privacidade', pageName);
  }, []);

  return (
    <div id="privacy-page" className="container">
      <div className='privacy'>
        <div className='privacy-content'>
          <h1>Política de Privacidade e Segurança</h1>
          <p>A Política de Privacidade e Segurança foi criada para demonstrar o 
            compromisso e respeito de O Caçador de Sacerdotisas (Truenumbers - Autoconhecimento 
            e Desenvolvimento Pessoal Ltda - CNPJ 50.726.614/0001-23) com a segurança e a privacidade 
            de informações coletadas de nossos usuários.</p>
          <p>Todos os dados registrados no site não são usados para outra finalidade que a comunicação 
            e a melhora da qualidade de navegação em rede dos próprios usuários. O Caçador de Sacerdotisas
            não comercializa, não cede, não empresta qualquer dado dos inscritos para outros sites, 
            pessoas físicas ou empresas.</p>
          <p>Se você for menor de 18 (dezoito) anos, quando for mencionado "você", neste Termo, 
            será entendido que as declarações tenham sido outorgadas por seu responsável legal.</p>
          <p>As marcas, nomes, logotipos, domínios e demais símbolos distintivos, bem como os conteúdos 
            publicados neste site que não constituam contribuição de terceiros, são de propriedade 
            exclusiva de O Caçador de Sacerdotisas. Você não deverá copiar, reproduzir, republicar, transmitir, 
            distribuir ou de qualquer outra forma utilizar qualquer conteúdo ou sinais distintivos 
            de O Caçador de Sacerdotisas, parcial ou integralmente. Você também concorda que não criará qualquer 
            obra derivada ou adaptará qualquer conteúdo do Site para qualquer finalidade, sem a prévia 
            e expressa autorização.</p>
          <p>O Caçador de Sacerdotisas não será responsável por quaisquer perdas ou danos que venha a ocorrer 
            em decorrência do uso do site, tampouco garante que o site funcionará ininterruptamente 
            e livre de falhas, erros ou vírus.</p>
          <p>Se qualquer dispositivo deste Termo de Uso for declarado inválido ou inexequível em 
            decorrência de decisão judicial ou de autoridade administrativa, todos os demais 
            permanecerão em vigor. O presente Termo de uso será interpretado de acordo com as 
            leis brasileiras.</p>
          <strong>Termo de Uso e privacidade</strong>
          <p>1) As informações pessoais fornecidas pelos usuários ao O Caçador de Sacerdotisas são de uso 
            restrito do mesmo. Elas são utilizadas com o propósito básico de identificar o perfil 
            do público usuário, com o objetivo de personalizar os serviços disponíveis.</p>
          <p>2) De maneira alguma serão divulgadas informações sobre seus usuários e sua navegação, 
            a menos que seja obrigado a fazê-lo mediante ordem judicial ou no estrito cumprimento de 
            norma legal.</p>
          <p>3) O Caçador de Sacerdotisas envia e-mails somente para o endereço eletrônico do usuário que 
            tenha se cadastrado para recebê-los.</p>
          <p>4) O Caçador de Sacerdotisas e os sites que ele hospeda oferecem links para páginas de terceiros 
            na Internet. O Caçador de Sacerdotisas não pode se responsabilizar pela política de privacidade 
            praticada por esses sites hospedados e pelos sites acessados por meio dos referidos links.</p>
          <p>5) O Caçador de Sacerdotisas e os sites hospedados em seus servidores podem, eventualmente, 
            gravar arquivos de dados em seu computador chamados "cookies". Geralmente, eles incluem 
            datas ou mensagens na forma de texto. Por meio dos cookies, os sistemas automatizados de 
            O Caçador de Sacerdotisas, assim como os dos titulares dos sites hospedados, podem identificar suas 
            visitas anteriores, personalizar sua página de abertura de sites ou inibir a exibição de 
            conteúdo já acessado anteriormente. Caso queira impedir a gravação de cookies, o usuário 
            pode desabilitar essa opção seguindo os comandos existentes nos menus de personalização 
            de seu navegador (browser).</p>
          <p>6) Salas de Chat, fóruns e outras áreas públicas de interatividade de O Caçador de Sacerdotisas não 
            podem ser controladas por sua Política de Privacidade. Assim, O Caçador de Sacerdotisas desaconselha 
            FORTEMENTE a exposição de dados pessoais nos citados ambientes virtuais que possam prejudicar 
            o usuário.</p>
          <Link to='/grupo'>Voltar</Link>
        </div>
      </div>
      <div className="footer-background">
        <Footer showPrivacyTerms={false} pageName={pageName}/>   
      </div>  
    </div>
  );
}

export default PrivacyTerms;
