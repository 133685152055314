import React from "react";
import { Link } from "react-router-dom";

import useAnalyticsEventTracker from '../../../utils/useAnalyticsEventTracker';

import Footer from "../Footer";

import tablet from '../../../assets/images/tablet.png';

import './styles.css';

const Ebook: React.FC = () => {

  const pageName = 'Página e-book';
  const gaEventTracker = useAnalyticsEventTracker(pageName);

  const linkClubeDeAutores = 'https://clubedeautores.com.br/livro/o-cacador-de-sacerdotisas';

  return (
    <div className="ebook">
      <div className="ebook-content">
        <div className="ebook-block">
          <div className="ebook-story">
            <h1>E-BOOK E LIVRO</h1>
            <div className="pergaminho">
              <p>Nas páginas deste livro, convido você a mergulhar em uma narrativa
                fascinante, "O Caçador de Sacerdotisas", que nos transporta aos tempos
                ancestrais da lendária Atlântida. Esta é uma história que transcende as eras,
                uma jornada que nos levará através do tempo, para que possamos
                redescobrir a nós mesmos e compreender os obstáculos da vida de maneira
                mais profunda.</p>
              <p>O protagonista desta saga enfrenta desafios extraordinários em sua missão
                de proteger o conhecimento e a sabedoria guardados pelas sacerdotisas
                atlantes. Seus feitos e dilemas ressoam através dos séculos, lançando luz
                sobre nossa própria jornada pessoal e os desafios universais que
                enfrentamos.</p>
              <p>À medida que desvendamos as páginas deste livro, somos lembrados de que,
                apesar das eras que nos separam, todos compartilhamos a busca por
                significado e a superação de obstáculos. Esta é uma história que nos
                incentiva a explorar o passado para entender melhor o presente, uma
                jornada de autodescoberta que nos inspirará a enfrentar os desafios da vida
                com coragem e determinação.</p>
            </div>
            <strong>Aqui você participa também!</strong>
            <div id="text-box">
              <Link to='/conte-sua-historia'>
                <strong>Conte aqui a sua história</strong>              
              </Link>          
            </div>
          </div>
          <div className="ebook-tablet">
            <img src={tablet} alt="tablet"/>
            <div className="tablet-links">
              <div id="text-box-tablet">
                <a href={linkClubeDeAutores} target='_blank' rel='noreferrer noopener' onClick={()=> gaEventTracker.eventTracker('Visitar loja clube de autores', 'Visitar loja clube de autores')}>
                  <strong>COMPRE AQUI</strong>              
                </a>          
              </div>              
            </div>            
          </div>
        </div>        
        <Footer showPrivacyTerms={false} pageName={pageName}/>
      </div>     
    </div>    
  );
}

export default Ebook;
