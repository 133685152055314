import { useEffect } from 'react';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import Ebook from '../../components/PageComponents/Ebook';

import './styles.css';

function EbookPage() {

  const pageName = 'Página e-book'
  const gaEventTracker = useAnalyticsEventTracker(pageName);

  useEffect(() => {
    gaEventTracker.sendPageView('/ebook', pageName);
  }, []);

  return (
    <div id="ebook-page" className="container">
      <Ebook />
    </div>
  );
}

export default EbookPage;
