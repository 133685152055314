import { useEffect } from 'react';

import useAnalyticsEventTracker from '../../utils/useAnalyticsEventTracker';

import Main from '../../components/PageComponents/Main';

import './styles.css';

function Landing() {

  const pageName = 'Página principal'
  const gaEventTracker = useAnalyticsEventTracker(pageName);

  useEffect(() => {
    gaEventTracker.sendPageView('/', pageName);
  }, []);

  return (
    <div id="landing-page" className="container">
      <Main />
    </div>
  );
}

export default Landing;
